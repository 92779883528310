export default function IconRight() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="-0.75 -0.75 16 16"
      id="Arrows-Button-Right--Streamline-Micro"
      height="100%"
      width="100%"
    >
      <desc>{'Arrows Button Right Streamline Icon: https://streamlinehq.com'}</desc>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3.9875000000000007 0.7250000000000001 10.295 6.736700000000001a0.7250000000000001 0.7250000000000001 0 0 1 0 1.0266000000000002L3.9875000000000007 13.775000000000002"
        strokeWidth={1.5}
      />
    </svg>
  );
}
